import * as React from "react";
import AboutGoGoIsland from "../components/aboutGoGoIsland";
import AboutInstagram from "../components/aboutInstagram";
import Footer from "../components/footer";
import Header from "../components/header";
import PageHeader from "../components/pageHeader";

const History = () => {
  return (
    <React.Fragment>
      <Header />
      <main>
        <PageHeader page="history">HISTORY</PageHeader>
        <AboutGoGoIsland />
        <AboutInstagram />
      </main>
      <Footer />
    </React.Fragment>
  );
};

export default History;

export const Head = () => <title>GoGo Island | HISTORY</title>
