import * as React from "react";
import GogoIslandImg from "../images/history.png";
import * as styles from "../styles/sass/layout/_aboutGoGoIsland.module.scss"

const AboutGoGoIsland = () => {
  return (
    <>
      <div className="common__layout">
        <h2 className="common__title">GoGoIslandについて</h2>
      </div>
      <div className={styles.aboutGogoisland}>
          <p>70年以上続く柑橘農家【GoGoIsland】と申します。</p>
          <p>私たちは、愛媛県松山市の高浜港沖合２㎞に位置する小さな島【興居島　ごごしま】で柑橘農家を営んでおります。</p>
          <p>祖父の代から三代続く柑橘農家で、子供達を初め、多くの方に安心して食べてもらえるよう、減農薬に取り組んでいます。</p>
          <p>興居島産まどんな、せとか、宮内いよかん、カラマンダリン、4種を厳選して取り扱いしています。</p>
          <img src={GogoIslandImg} alt="興居島について" />
          <p>私が嫁いだのは愛媛県の松山市に属する小さな島、興居島の柑橘農家でした。</p>
          <p>主人とまだ付き合っていた頃、収穫時期に初めてお手伝いに行きました。その時、山の上から私が見たのは、柑橘の木の濃い緑色、その木になる実のオレンジ色、そして瀬戸内海の青い海でした。とても美しく感動して、10年以上経った今でもその光景をはっきりと覚えています。</p>
          <p>主人のお父さんやお母さんが作る柑橘は、同じ愛媛で育った私もびっくりなほど、とても甘く、そのジューシーな食感に感動しました。</p>
          <p>私が感動したこの柑橘をより多くの方に知っていただきたいと思い、GoGoIslandを立ち上げました。</p>
      </div>
    </>
  );
};

export default AboutGoGoIsland;